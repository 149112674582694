<script setup>
/* Imports */
import {
  computed,
  ref,
} from 'vue';

/* Helpers */
import {
  shouldDisplayBeforePriceAndUp,
  mapGetters,
  mapActions,
} from '../../helpers/mainHelpers';
import { replaceSuperScript } from '@garmin/text-utils';

/* Store - Getters */
const {
  getTranslations: translations,
  getPartNumber: partNumb,
  getDrawerMode: drawerMode,
  getLocale: locale,
} = mapGetters();

/* Store - Actions */
const {
  removePolygon,
  setPolygon,
  setPartNumber,
  setDrawerMode,
} = mapActions();

/* Props */
const props = defineProps({
  maps: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

/* State */
const state = ref({
  displayBeforePricePriceAndUpText: shouldDisplayBeforePriceAndUp(locale.value),
});

/* Computed */
const switchToggled = computed(() => (partNumber) => partNumb.value === partNumber);

/**
 * Handles the superscript in the map name
 * @param {string} name - The map name
 * @returns {string}    - The map name with the superscript replaced
 */
const mapName = (name) => replaceSuperScript(name);

// Returns the price with the before and up text || and up text when price has only variation
const hasPriceVariation = (product) => {
  if (product.isEms && product.hasPriceVariation) {
    return state.value.displayBeforePricePriceAndUpText
      ? `${translations.value.ITFE_MARINE_MAPS_BEFORE_AND_UP} ${product.formattedPrice}`
      : `${product.formattedPrice} ${translations.value.ITFE_MARINE_MAPS_AND_UP}`;
  }
  return product.formattedPrice;
};

// Returns the before and up text when promoted pricing
/* eslint-disable-next-line consistent-return */
const hasBeforeAndUpPromotedPricing = (product) => {
  if (product.isEms && product.hasPriceVariation) {
    return state.value.displayBeforePricePriceAndUpText ? `${translations.value.ITFE_MARINE_MAPS_BEFORE_AND_UP}` : '';
  }
};

// Returns the and up text when promoted pricing
/* eslint-disable-next-line consistent-return */
const hasAndUpPromotedPricing = (product) => {
  if (product.isEms && product.hasPriceVariation) {
    return state.value.displayBeforePricePriceAndUpText ? '' : `${translations.value.ITFE_MARINE_MAPS_AND_UP}`;
  }
};

/**
 * Returns the coordinates array for the polygon
 * @param {object} polygon - The polygon object
 * @returns {Array}        - The coordinates array
 */
const getCoordinates = (polygon) => {
  if (polygon.type === 'MultiPolygon') {
    return polygon.multiPolygonCoordinates;
  }
  if (polygon.type === 'Polygon') {
    return polygon.polygonCoordinates;
  }
  return [];
};

/* Handle the drawer mode */
const changeDrawerMode = () => {
  // when user clicks view on map and the drawer is full height,
  // make the drawer half way, so that the user can see the map
  if (drawerMode.value === 'top') {
    setDrawerMode('middle');
  }
};

/**
 * Checks coordinates array to see if any value crosses anti-meridian
 * Returns true if coordinates are good as is
 * Returns false if coordinates need adjusted
 * @param {Array} coordinates - The coordinates array
 * @returns {boolean}         - True if coordinates are good as is, false if coordinates need adjusted
 */
const checkCoordinates = (coordinates) => {
  let valid = false;
  if (coordinates) {
    let longsOverZero = 0;
    let totalLatLongs = 0;
    let maxLongitude = null;
    let minLongitude = null;

    const totalPolygons = coordinates.length;
    for (let i = 0; i < totalPolygons; i += 1) {
      const singlePolygon = coordinates[i];

      // Hardcode [0] since inner array is always at [0]
      const singlePolygonCoordinateCount = singlePolygon[0].length;
      for (let j = 0; j < singlePolygonCoordinateCount; j += 1) {
        const longitude = coordinates[i][0][j][1];

        if (!maxLongitude || maxLongitude < longitude) {
          maxLongitude = longitude;
        }
        if (!minLongitude || minLongitude > longitude) {
          minLongitude = longitude;
        }
        if (longitude >= 0) {
          longsOverZero += 1;
        }
        totalLatLongs += 1;
      }
    }
    const allSameSign = longsOverZero === 0 || longsOverZero === totalLatLongs;

    if (allSameSign) {
      valid = true;
    } else {
      valid = maxLongitude - minLongitude < 180;
    }
  }
  return valid;
};

/**
 * This fixes a known issue with Leaflet when a polygon crosses the anti-meridian.
 * We subtract 360 from any longitude value > 0 to avoid it wrapping around the map.
 * @param {*} coordinates - The coordinates array
 * @returns {Array}       - The new coordinates array
 */
const processCoordinates = (coordinates) => {
  // Create a deep copy of the coordinates array
  const newCoordinates = JSON.parse(JSON.stringify(coordinates));

  const totalPolygons = newCoordinates.length;
  for (let i = 0; i < totalPolygons; i += 1) {
    const singlePolygon = newCoordinates[i];

    // Hardcode [0] since inner array is always at [0]
    const singlePolygonCoordinateCount = singlePolygon[0].length;
    for (let j = 0; j < singlePolygonCoordinateCount; j += 1) {
      const longitude = newCoordinates[i][0][j][1];
      if (longitude > 0) {
        newCoordinates[i][0][j][1] = longitude - 360;
      }
    }
  }

  return newCoordinates;
};

/* Handle the polygon */
const changePolygon = (map) => {
  changeDrawerMode();
  const { polygon } = map;
  const partNumber = map.sku;
  if (partNumber === partNumb.value) {
    removePolygon();
  } else {
    let coordinates = getCoordinates(polygon);

    // Checks if longitude values cross anti-meridian
    const validCoordinates = checkCoordinates(coordinates);

    // Subtract 360 from longitude values > 0 to fix anti-meridian wrapping
    if (!validCoordinates) {
      coordinates = processCoordinates(coordinates);
    }

    setPolygon({
      dashed: polygon.dashed,
      type: polygon.type,
      coordinates,
    });
    setPartNumber(partNumber);
  }
};
</script>

<template>
  <div v-if="!props.isLoading">
    <div
      v-for="(map, i) in props.maps"
      :key="i"
      class="result-block"
    >
      <div
        class="result-block__info"
        @click.prevent="changePolygon(map)"
        @keyup.enter.prevent="changePolygon(map)"
      >
        <g-heading
          class="result-block__info__title"
          :content="mapName(map.name)"
          heading-size="3"
        />
        <p
          v-if="map.formattedPrice?.length"
          class="result-block__info__buy__price"
        >
          <g-price
            v-if="map.formattedSalePrice"
            :original-price="map.formattedListPrice"
            :discounted-price="map.formattedSalePrice"
            has-text="true"
            :and-up-text="hasAndUpPromotedPricing(map)"
            :from-text="hasBeforeAndUpPromotedPricing(map)"
          />
          <g-copy
            v-else
            type="normal"
          >
            {{ hasPriceVariation(map) }}
          </g-copy>
        </p>
        <g-switch
          class="result-block__switch"
          size="large"
          :right-label="translations.ITFE_MARINE_MAPS_VIEW_ON_MAP"
          tabindex="0"
        >
          <input
            type="checkbox"
            name="resultSwitch"
            :checked="switchToggled(map.sku)"
            @update="changePolygon(map)"
          >
        </g-switch>
      </div>
      <div class="result-block__info__link">
        <a
          :href="map.skuBuyUrl"
          class="result-block__info__link__result"
          size="medium"
        >
          {{ translations.ITFE_MARINE_MAPS_RESULT_BUTTON_TEXT }}
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.result-block {
  display: flex;
  flex-wrap: wrap;
  background: $color-white;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.24); // the same as it is applied in GC
  margin-bottom: 1rem;

  &:first-child {
    margin-top: 0.75rem;
  }

  &:last-child {
    margin-bottom: 17.5rem;
  }

  @include breakpoint('sm') {

    &:first-child {
      margin-top: 1.25rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info {
    width: 100%;
    padding: 0 1rem;

    &:hover {
      cursor: pointer;
    }

    &__title {
      color: $color-black;

      :deep(h3) {
        line-height: 1.25;
      }
    }

    &__buy {
      width: 100%;
      align-items: top;
      justify-content: space-between;

      &__price {
        @include font-primary();
        color: $color-black;
        font-size: 1.125rem;
        padding: 0.5rem 0;
        min-width: 30px;
        margin: 0;
      }
    }

    &__link {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      &__result {
        @include font-primary();
        width: 100%;
        text-align: right;
        text-transform: uppercase;
        cursor: pointer;
        text-decoration: none;
        font-size: 1rem;
        @include font-primary-weight-medium();
        padding: 0 1rem 0.625rem;
        letter-spacing: 0;
        color: $color-black;

        &:hover {
          text-decoration: underline;
          color: $color-black;
        }
      }
    }
  }

  &__switch {
    padding: 1rem 0;
  }
}

// font-size for the switch label
:deep(.g__switch__label__text--right) {
  font-size: 0.875rem;
}

:deep(.g__copy) {
  margin-bottom: 0;
}

/**
  * g-switch -> Global Component
  * Prevent the input from breaking the screen
  * when the user navigates through cards by pressing tab
  */
.g__switch input {
  display: inline-block;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  white-space: nowrap;
  visibility: hidden;
}
</style>
