<script setup>
/* Imports */
import { ref } from 'vue';

/* Helpers */
import {
  mapGetters,
  mapActions,
} from '../../helpers/mainHelpers';

/* Components */
import OldResults from '../../components/OldMarineMaps/OldResults.vue';
import OldSearch from '../../components/OldMarineMaps/OldSearch.vue';
import OldSearchOptions from '../../components/SearchOptions.vue';

/* Store - Getters */
const {
  getIsSearched: isSearched,
  getDrawerMode: drawerMode,
} = mapGetters();

/* Store - Actions */
const {
  setDrawerMode,
} = mapActions();

/* State */
const state = ref({
  startPosition: '',
  movingPosition: '',
  endPosition: '',
  isDragging: false,
});

/* Methods */
const disableScroll = () => {
  document.querySelector('html').classList.add('overflow__hidden');
  document.querySelector('body').classList.add('overflow__hidden');
};

const enableScroll = () => {
  // wait for transition to finish
  setTimeout(() => {
    document.querySelector('html').classList.remove('overflow__hidden');
    document.querySelector('body').classList.remove('overflow__hidden');
  }, 500);
};

const initPositioning = (event) => {
  disableScroll();

  if (event?.touches) {
    // set start position and moving position
    state.value.startPosition = event?.touches[0]?.clientY;
    state.value.movingPosition = event?.touches[0]?.clientY;
  }
};

const updateHeight = (heightDiff, currentPosition) => {
  const drawer = document.getElementById('drawer-mobile');
  const drawerHeight = drawer.offsetHeight;
  const newHeight = Number(drawerHeight + heightDiff);

  // update with new height
  drawer.style.height = `${newHeight.toString()}px`;
  drawer.style.maxHeight = `${newHeight.toString()}px`;

  // update current position
  state.value.movingPosition = currentPosition;
};

const moveDrawer = (event) => {
  disableScroll();

  // prevent transition when dragging the drawer
  if (!state.value.isDragging) {
    const drawer = document.getElementById('drawer-mobile');
    drawer.classList.add('drawer__dragging');
    state.value.isDragging = true;
  }

  if (event?.touches) {
    const currentPosition = event?.touches[0]?.clientY;
    const heightDiff = state.value.movingPosition - currentPosition;

    // update drawer with new height
    updateHeight(heightDiff, currentPosition);
  }
};

const checkPosY = () => {
  const windowHeight = window.innerHeight;
  const diffY = state.value.endPosition - state.value.startPosition;
  const absDiffY = Math.abs(diffY);

  if (absDiffY > 10) {
    if (drawerMode.value === 'top') {
      // user goes can only go down
      if (absDiffY <= windowHeight / 2) {
        setDrawerMode('middle');
      } else {
        setDrawerMode('bottom');
      }
      return;
    }

    if (drawerMode.value === 'middle') {
      // user can go both ways
      if (diffY >= 0) {
        setDrawerMode('bottom');
      } else {
        setDrawerMode('top');
      }
      return;
    }

    // don't change the drawer mode if the user didn't search anything
    // and the drawer is at the bottom
    if (drawerMode.value === 'bottom' && isSearched.value) {
      // user goes can only go up
      if (absDiffY <= windowHeight / 2) {
        setDrawerMode('middle');
      } else {
        setDrawerMode('top');
      }
    }
  }
};

const setEndPosition = (event) => {
  if (event?.changedTouches) {
    state.value.endPosition = event?.changedTouches[0]?.clientY;
  }
  const drawer = document.getElementById('drawer-mobile');

  // start transition when finishing dragging
  drawer.classList.remove('drawer__dragging');
  state.value.isDragging = false;
  drawer.style.height = null;
  drawer.style.maxHeight = null;
  checkPosY();

  // reset positioning
  state.value.startPosition = '';
  state.value.movingPosition = '';
  state.value.endPosition = '';
  enableScroll();
};
</script>

<template>
  <div
    id="drawer-mobile"
    :class="[drawerAnimation, 'drawer']"
  >
    <div
      :class="[
        'drawer__results',
        'drawer__results__background']
      "
    >
      <div class="drawer__padding__search">
        <div
          v-touch:press="initPositioning"
          v-touch:drag="moveDrawer"
          v-touch:release="setEndPosition"
        >
          <div class="drawer__handler" />
          <OldSearch />
          <OldSearchOptions />
        </div>
      </div>
      <div class="drawer__results__scrollbar">
        <OldResults
          class="drawer__padding__results"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.drawer {
  bottom: 0;
  left: 0;
  max-height: 5.5rem;
  width: 100%;
  position: absolute;
  background: transparent;
  transition: margin-right 0.5s cubic-bezier(0.52, -0.57, 0.42, 1.57) 0s;
  display: flex;
  -webkit-overflow-scrolling: touch;
  z-index: 1000; // Allows drawer to render above leaflet map

  &__padding {

    &__results {
      padding: 0 1.25rem;
    }

    &__search {
      padding: 0.75rem 1.25rem 0 1.25rem;
    }
  }

  &__results {
    background-color: transparent;
    width: 100%;
    height: 100%;

    &__background {
      background-color: $color-gray-95;
    }

    &__scrollbar {
      height: 100%;
      max-height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  &__handler {
    cursor: grab;
    border: 2px solid $color-gray-45;
    border-radius: 5px;
    margin: 0 45% 0.5rem 45%;
  }

  &__mode {

    &__bottom {
      max-height: 6.5rem;
      height: 6.5rem;
    }

    &__middle {
      max-height: 50%;
      height: 50%;
    }

    &__top {
      max-height: $drawerHeightTop;
      height: $drawerHeightTop;
    }
  }

  &:not(.drawer__dragging) {
    transition: height 300ms ease-in, max-height 300ms ease-in;
  }
}

.overflow__hidden {
  overflow: hidden;
  margin: 0;
  height: 100%;
  // disable pull-to-refresh while dragging
  overscroll-behavior-y: contain;
}
</style>
