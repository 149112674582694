<script setup>
/* Imports */
import { computed, ref } from 'vue';

/* Helpers */
import {
  convertUnit,
  mapActions,
  mapGetters,
} from '../../helpers/mainHelpers';

/* Components */
import ArrowDown from '@garmin/regalia/icons/arrow-down/icon.vue';
import SettingsIcon from '../../components/icons/SettingsIcon.vue';

/* Store - Getters */
const {
  getTranslations: translations,
  getChartDepth: depthUnit,
  getSafetyDepth: safetyDepth,
  getChartType: chartType,
  getDrawerMode: drawerMode,
} = mapGetters();

/* Store - Actions */
const {
  setChartType,
  setChartDepth,
  setSafetyDepth,
  setDrawerMode,
} = mapActions();

/* Props */
const props = defineProps({
  openPosition: {
    type: String,
    default: '',
  },
});

/* State */
const state = ref({
  mapSettingsOpen: false,
  previousOpenPosition: '',
});

/* Computed */
const dropdownArrowClasses = computed(() => ({
  'map-settings__arrow': true,
  'map-settings__arrow--active': state.value.mapSettingsOpen,
}));

const mapSettingsClass = computed(() => ({
  'map-settings__container': true,
  'map-settings__container--top': props.openPosition === 'top',
  'map-settings__container--bottom': props.openPosition === 'bottom',
}));

const setMaxValue = computed(() => {
  const maxValues = {
    m: '10',
    fath: '5.5',
    ft: '33',
  };

  return maxValues[depthUnit.value] || '';
});

const setStepValue = computed(() => {
  const stepValues = {
    m: '0.1',
    fath: '0.1',
    ft: '1',
  };

  return stepValues[depthUnit.value] || '';
});

const translatedDepthUnit = computed(() => {
  const unitTranslations = {
    m: translations.value.ITFE_MARINE_MAPS_METERS_ABBREVIATION,
    fath: translations.value.ITFE_MARINE_MAPS_FATHOMS_ABBREVIATION,
    ft: translations.value.ITFE_MARINE_MAPS_FEET_ABBREVIATION,
  };

  return unitTranslations[depthUnit.value] || '';
});

/* Methods */
const toggleMapSettings = () => {
  state.value.mapSettingsOpen = !state.value.mapSettingsOpen;
  // Slide the drawer to the bottom when opening map settings
  // Return the drawer to its previous position on close
  if (state.value.mapSettingsOpen) {
    state.value.previousOpenPosition = drawerMode.value;
    setDrawerMode('bottom');
  } else if (state.value.previousOpenPosition !== '') {
    setDrawerMode(state.value.previousOpenPosition);
  }
};

const changeChartType = (e) => {
  setChartType(e?.target?.value);
};

const changeChartDepth = (e) => {
  setSafetyDepth(convertUnit(safetyDepth.value, depthUnit.value, e?.target?.value));
  setChartDepth(e?.target?.value);
};

const changeSafetyDepth = (e) => {
  setSafetyDepth(e?.target?.value);
};
</script>

<template>
  <div
    class="map-settings__notice"
    tabindex="0"
    @click="toggleMapSettings"
    @keydown.enter="toggleMapSettings"
  >
    <g-copy
      type="detail"
      class="map-settings__dropdown-title"
    >
      {{ translations.ITFE_MARINE_MAPS_MAP_OPTIONS }}
    </g-copy>

    <ArrowDown :class="dropdownArrowClasses" />

    <SettingsIcon class="map-settings__icon" />

    <div
      v-if="state.mapSettingsOpen"
      :class="mapSettingsClass"
      @click.stop
    >
      <div class="map-settings__container--chart-type">
        <g-heading
          class="map-settings__container__heading"
          :content="translations.ITFE_MARINE_MAPS_CHART_TYPE_HEADING"
          heading-size="3"
        />
        <g-radio :label="translations.ITFE_MARINE_MAPS_CHART_TYPE_ONE">
          <input
            name="chartViewer"
            type="radio"
            value="nav"
            :checked="chartType === 'nav'"
            @change="changeChartType($event)"
          >
        </g-radio>
        <g-radio :label="translations.ITFE_MARINE_MAPS_CHART_TYPE_TWO">
          <input
            name="chartViewer"
            type="radio"
            value="fish"
            :checked="chartType === 'fish'"
            @change="changeChartType($event)"
          >
        </g-radio>
      </div>
      <div class="map-settings__container--depth-unit">
        <g-heading
          class="map-settings__container__heading"
          :content="translations.ITFE_MARINE_MAPS_DEPTH_UNIT_HEADING"
          heading-size="3"
        />
        <g-radio :label="translations.ITFE_MARINE_MAPS_UNIT_OF_MEASURE_FEET">
          <input
            name="depthUnit"
            type="radio"
            value="ft"
            :checked="depthUnit === 'ft'"
            @change="changeChartDepth($event)"
          >
        </g-radio>
        <g-radio :label="translations.ITFE_MARINE_MAPS_UNIT_OF_MEASURE_METERS">
          <input
            name="depthUnit"
            type="radio"
            value="m"
            :checked="depthUnit === 'm'"
            @change="changeChartDepth($event)"
          >
        </g-radio>
        <g-radio :label="translations.ITFE_MARINE_MAPS_UNITS_OF_MEASURE_FATHOM">
          <input
            name="depthUnit"
            type="radio"
            value="fath"
            :checked="depthUnit === 'fath'"
            @change="changeChartDepth($event)"
          >
        </g-radio>
      </div>
      <div class="map-settings__container--depth-safety">
        <g-heading
          class="map-settings__container__heading"
          :content="`${translations.ITFE_MARINE_MAPS_SAFETY_DEPTH} ${safetyDepth} ${translatedDepthUnit}`"
          heading-size="3"
        />
        <div class="safety-depth-slider-wrap">
          <div class="safety-depth-slider-min">
            0
          </div>
          <input
            id="safetyDepth"
            name="safetyDepth"
            type="range"
            min="0"
            :max="setMaxValue"
            :value="safetyDepth"
            :step="setStepValue"
            @change="changeSafetyDepth($event)"
          >
          <div class="safety-depth-slider-max">
            {{ setMaxValue }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.map-settings {

  &__notice {
    @include font-primary();
    margin: 1rem;
    padding: 0.375rem 0.3125rem;
    background: $color-white;
    @include make-drop-shadow();
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    @include font-primary-weight-medium();
    position: relative;
    cursor: pointer;

    @include breakpoint('sm') {
      padding: 0.8125rem 0.5rem;
    }
  }

  &__arrow {
    display: none;
    position: relative;
    margin: 0 10px;
    transition: transform 0.2s ease;
    width: 0.875rem;
    height: 0.875rem;
    left: 3px;
    top: 1px;

    &--active {
      display: none;
      position: relative;
      margin: 0 10px;
      width: 0.875rem;
      height: 0.875rem;
      top: 1px;
      transition: transform 0.2s ease;
      transform: rotate(-180deg);

      @include breakpoint('sm') {
        display: block;
      }
    }

    @include breakpoint('sm') {
      display: block;
    }
  }

  &__dropdown-title {
    display: none;
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 0.8125rem;

    @include breakpoint('sm') {
      display: block;
    }
  }

  &__icon {
    width: 30px;
    fill: $color-gray-50;
    display: block;

    @include breakpoint('sm') {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    @include font-primary();
    position: absolute;
    padding: 15px;
    background: $color-white;
    width: 13rem;

    &--top {
      bottom: 0;
      right: 0;
      margin-bottom: 40px;
    }

    &--bottom {
      top: 0;
      left: 0;
      margin-top: 40px;
    }

    &--depth-unit,
    &--chart-type {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5em;
    }

    :deep(.g__heading) {

      h3 {
        margin: 0;
        cursor: auto;
        text-transform: uppercase;
        padding-bottom: 0.5rem;
        padding-top: 0;
        font-size: 1rem;
      }
    }
  }
}

.safety-depth-slider-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include font-primary-weight-regular();

  #safetyDepth {
    cursor: pointer;
  }
}

:deep(.g__radio__label) {
  padding: 0.5em 0;
  @include font-primary-weight-regular();

  &::before {
    width: 1.2rem;
    height: 1.2rem;

    input[type = 'radio'] + & {
      position: relative;
      top: -1px;
      margin-right: 0.7rem;
    }
  }
}
</style>
