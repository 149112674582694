<script setup>
/* Imports */
import {
  ref,
  onBeforeMount,
  computed,
  watch,
} from 'vue';

/* Helpers */
import {
  mapGetters,
  mapActions,
  timeIntervalGenerator,
} from './helpers/mainHelpers';

/* Composables */
import {
  useLocalStorage,
  useUrlSearchParams,
  useTitle,
} from '@vueuse/core';
import { useRouteQuery } from '@vueuse/router';
import { useScreenWidth } from './composables/useScreenWidth';
import { useProvideMultiple } from './composables/useProvideMultiple';

/* Old Components */
/* TODO: After Navionics is released remove these components and imports */
import OldMapViewer from './components/OldMarineMaps/OldMapViewer.vue';
import OldDrawerMobile from './containers/OldMarineMaps/OldDrawerMobile.vue';
import OldDrawerDesktop from './containers/OldMarineMaps/OldDrawerDesktop.vue';

/* Components */
import MapViewer from './components/MapViewer.vue';
import DrawerMobile from './containers/DrawerMobile.vue';
import DrawerDesktop from './containers/DrawerDesktop.vue';
import Interstitial from './components/Interstitial.vue';

/* Initialize composables */
const searchParams = useUrlSearchParams('history');
const lastViewedMapBrand = useLocalStorage('lastViewedMapBrand');
// Initialize composables with query params if they are set
const mapsQueryParam = useRouteQuery('maps', searchParams.maps);
const overlayQueryParam = useRouteQuery('overlay', searchParams.overlay);
const heatmapQueryParam = useRouteQuery('heatmap', searchParams.heatmap);
const { isMobile } = useScreenWidth();
const { provideMultiple } = useProvideMultiple();

/* Store - Getters */
const {
  getLocale: locale,
  getSeoTitle: seoTitle,
  getActiveBrand: activeBrand,
  // TODO: Remove getUseNavionicsPowerViewer after Navionics is released
  getUseNavionicsPowerViewer: useNavionicsPowerViewer,
  getTranslations: translations,
  getNoResults: noResults,
  getIsError: isError,
  getIsWorldwideUpdates: isWorldwideUpdates,
  getIsLoading: isLoading,
  getIsInitialMapLocationSet: isInitialMapLocationSet,
  getSearchType: searchType,
  getShowGarminMaps: showGarminMaps,
  getShowInterstitialOverlay: showInterstitialOverlay,
  getWorldwideNumberOfUpdates: worldwideNumberOfUpdates,
  getPolygonNumberOfUpdates: polygonNumberOfUpdates,
  getSelectedPeriod: selectedPeriod,
  getSubdirectory: subdirectory,
} = mapGetters();

/* Store - Actions */
const {
  setInitialState,
  setActiveBrand,
  setChartType,
  setSearchType,
  fetchNumberOfUpdateMultiPolygon,
  fetchWorldwideNumberOfUpdates,
  setSelectedPeriod,
} = mapActions();

/* End date for number of updates period interval - today's day */
const endUpdatesTimestamp = ref(new Date());

/* Computed -> shouldDisplayInterstitial */
// TODO: Remove useNavionicsPowerViewer after Navionics is released
const shouldDisplayInterstitial = computed(() => useNavionicsPowerViewer.value && showInterstitialOverlay.value);

const displayDrawerDesktop = (navionicsPowerViewer) => navionicsPowerViewer && !isMobile.value;

const displayDrawerMobile = (navionicsPowerViewer) => navionicsPowerViewer && isMobile.value;

/* Provide */
provideMultiple({
  locale,
  translations,
  noResults,
  isError,
  // TODO: Remove useNavionicsPowerViewer after Navionics is released
  useNavionicsPowerViewer,
  isWorldwideUpdates,
  isHeatmap: computed(() => heatmapQueryParam.value === 'true'),
  isLoading,
  lastViewedMapBrand,
  searchType,
  setSearchType,
  worldwideNumberOfUpdates,
  polygonNumberOfUpdates,
  updatesTimeframes: timeIntervalGenerator(endUpdatesTimestamp.value),
  fetchNumberOfUpdateMultiPolygon,
  fetchWorldwideNumberOfUpdates,
  selectedPeriod,
  setSelectedPeriod,
  showGarminMaps,
  subdirectory,
});

/* Watch for changes to the local storage lastViewedMapBrand value, the maps query param, and the overlay query param */
watch(
  [lastViewedMapBrand, mapsQueryParam, overlayQueryParam],
  ([newLastViewedMapBrandValue, newMapsQueryParamValue, newOverlayQueryParam]) => {
    setActiveBrand({
      localStorageBrandValue: newLastViewedMapBrandValue,
      mapsQueryParamBrandValue: newMapsQueryParamValue,
      overlayQueryParamValue: newOverlayQueryParam,
    });
  },
);
/* Watch -> Set default chart type when switching between garmin and navionics charts */
watch(activeBrand, (newActiveBrand) => {
  if (newActiveBrand === 'garmin') {
    setChartType('nav');
  } else {
    setChartType('nautical');
  }
});

/* Lifecycle Hooks */
onBeforeMount(() => {
  setInitialState();
  useTitle(seoTitle.value);
});
</script>

<template>
  <div
    id="marineMaps"
    role="main"
  >
    <g-global-styles />
    <div
      v-show="!shouldDisplayInterstitial"
      class="marine__container"
    >
      <!-- Need to get initial map location asynchronously before we load the Leaflet map -->
      <template v-if="isInitialMapLocationSet">
        <!-- TODO: After Navionics is released remove OldMarineMaps/OldMapViewer -->
        <MapViewer
          v-if="useNavionicsPowerViewer"
        />
        <OldMapViewer v-else />
      </template>

      <!-- TODO: After Navionics is released remove OldMarineMaps/OldDrawerDesktop component -->
      <DrawerDesktop
        v-if="displayDrawerDesktop(useNavionicsPowerViewer)"
        :key="mapsQueryParam"
        class="marine__drawer-desktop"
      />
      <OldDrawerDesktop
        v-else-if="displayDrawerDesktop(!useNavionicsPowerViewer)"
        class="marine__drawer-desktop"
      />

      <!-- TODO: After Navionics is released remove OldMarineMaps/OldDrawerMobile component -->
      <DrawerMobile
        v-if="displayDrawerMobile(useNavionicsPowerViewer)"
        :key="mapsQueryParam"
        class="marine__drawer-mobile"
      />
      <OldDrawerMobile
        v-else-if="displayDrawerMobile(!useNavionicsPowerViewer)"
        class="marine__drawer-mobile"
      />
    </div>
    <Interstitial v-show="shouldDisplayInterstitial" />
  </div>
  <router-view v-slot="{ Component }">
    <transition
      name="fade-view"
      mode="out-in"
    >
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<style lang="scss">
/* 100% minus the header and footer */
#app {
  height: auto;

  @include breakpoint('sm') {
    height: calc(100vh - 190px);
  }

  @include breakpoint('md') {
    height: calc(100vh - 253px);
  }

  @include breakpoint('lg') {
    height: calc(100vh - 149px);
  }

  & > div {
    height: 100%;
  }
}
</style>

<style lang="scss" scoped>
/* Drawer */
#marineMaps {
  position: relative;
  overflow: hidden;
  height: 100%;
  @extend %font-primary;
}

.marine {

  &__container {
    background-color: $color-gray-85;
    position: relative;
    height: 100%;
  }

  &__drawer {

    &-desktop {
      visibility: hidden;
      @include breakpoint('sm') {
        visibility: visible;
      }
    }

    &-mobile {
      visibility: visible;
      @include breakpoint('sm') {
        visibility: hidden;
      }
    }
  }
}
</style>
