<script setup>
/* Imports */
import {
  computed,
  ref,
  inject,
} from 'vue';

/* Helpers */
import {
  mapGetters,
  mapActions,
} from '../../helpers/mainHelpers';

/* Composables */
import { useProvideMultiple } from '../../composables/useProvideMultiple';

/* Icons */
import SearchIcon from '@garmin/regalia/icons/search/icon.vue';
import CloseIcon from '@garmin/regalia/icons/close/icon.vue';
import GooglePoweredImage from '../icons/GooglePoweredImage.vue';

/* Components */
import SearchErrors from '../SearchErrors.vue';
import ProductFilter from '../ProductFilter.vue';

/* Inject */
const translations = inject('translations');

/* Initialize composables */
const { provideMultiple } = useProvideMultiple();

/* Store - Getters */
const {
  getSearchQuery: searchQuery,
  getDrawerMode: drawerMode,
  getIsLoading: isLoading,
  getFormattedAddress: formattedAddress,
} = mapGetters();

/* Store - Actions */
const {
  performSearch: search,
  setSearchQuery,
  setIsSearched,
  setDrawerMode,
  removePolygon,
  setLocationMarker,
  setFormattedAddress,
  setActiveLake,
} = mapActions();

/* Refs */
const searchInput = ref(null);

/* Show close button if not loading and either there is a formatted address or a non-empty search query */
const showCloseButton = computed(() => !isLoading.value && (formattedAddress.value || searchQuery.value.trim() !== ''));

const displaySearchValue = computed(() => {
  if (!searchQuery.value) {
    return formattedAddress.value;
  }
  return searchQuery.value;
});

/* Methods */
const clearInput = () => {
  setSearchQuery('');
  setLocationMarker(null);
  removePolygon();
  setDrawerMode('bottom');
  setFormattedAddress(null);
  setActiveLake(null);
};

/* Update search query */
const updateSearch = (e) => {
  const { value } = e.target;
  setSearchQuery(value);

  // Hide close button & clear input if the user deletes search from keyboard
  if (!searchQuery.value) {
    clearInput();
  }
};

const submitSearch = async () => {
  if (searchQuery.value) {
    // If the user starts typing in the input field and then searches from input
    await search(encodeURIComponent(searchQuery.value));
  } else {
    // If the user comes to Marine Maps with a query param in the URL (without populating the input field) and then searches from input
    await search(encodeURIComponent(formattedAddress.value));
  }

  searchInput.value?.blur();

  setIsSearched(true);

  // If search box is at the bottom, move the drawer half-way
  if (drawerMode.value === 'bottom') {
    setDrawerMode('middle');
  }

  removePolygon();

  // If the user has an active lake and searches from input for a new location
  setActiveLake(null);
};

/* Provide */
provideMultiple({
  submitSearch,
});
</script>

<template>
  <div class="search">
    <ProductFilter />
    <div class="search__input">
      <g-input
        :label="translations.ITFE_MARINE_MAPS_INPUT_SEARCH"
        :label-for="translations.ITFE_MARINE_MAPS_INPUT_SEARCH"
        hide-label="true"
        @keyup.enter.prevent="submitSearch"
      >
        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
        <div slot="prepend">
          <SearchIcon
            class="g-icon-search"
            :aria-label="translations.ITFE_MARINE_MAPS_SEARCH_BUTTON"
            :title="translations.ITFE_MARINE_MAPS_SEARCH_BUTTON"
            @click="submitSearch"
          />
        </div>
        <input
          id="searchInput"
          ref="searchInput"
          :value="displaySearchValue"
          :aria-label="translations.ITFE_MARINE_MAPS_INPUT_SEARCH"
          :title="translations.ITFE_MARINE_MAPS_INPUT_SEARCH"
          type="text"
          :placeholder="translations.ITFE_MARINE_MAPS_SEARCH_PLACEHOLDER_TEXT"
          autocomplete="off"
          @input="updateSearch"
        >

        <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
        <div slot="append">
          <div
            v-if="showCloseButton"
            tabindex="0"
            @click="clearInput"
            @keydown.enter.prevent="clearInput"
          >
            <CloseIcon
              class="g-icon-close"
              :aria-label="translations.ITFE_MARINE_MAPS_CLOSE_SEARCH_BUTTON"
              :title="translations.ITFE_MARINE_MAPS_CLOSE_SEARCH_BUTTON"
              data-testid="search-close-button"
            />
          </div>
        </div>
      </g-input>
    </div>

    <GooglePoweredImage />

    <SearchErrors />
  </div>
</template>

<style lang="scss" scoped>
.search {

  &__input {
    position: relative;

    // Search Input set the icons inside the input
    [slot = 'prepend'],
    [slot = 'append'] {
      width: 0;
    }

    .g-icon {
      z-index: 1;
      fill: $color-black;
      cursor: pointer;

      &-search {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        left: 0.5rem;
        padding-top: 0.2rem;
      }

      &-close {
        position: relative;
        width: 1rem;
        height: 1rem;
        right: 1.5rem;
        top: 0.2rem;
      }
    }

    input {
      padding: 0.875rem 2.5rem;
    }
  }
}
</style>
